import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"


//./gifs/eye.gif
const AboutPage = () => (

   <Layout>
   	<SEO title="ABOUT" />

   	<h1 style={{textAlign:'center'}}>ABOUT</h1>

   	<div style={{width: '100%', maxWidth:'500px', minWidth: '300px'}}>

         <div 
            style={{
               minWidth: '100%',
               margin: 'auto',
               display: 'flex',
               flexDirection: 'row',
               justifyContent: 'center',
               marginBottom: '60px'
            }}
         >
            <img src={`/../gifs/eye.gif`}/>
            <img src={`/../gifs/eye.gif`}/>
            <img src={`/../gifs/eye.gif`}/>
            <img src={`/../gifs/eye.gif`}/>
            <img src={`/../gifs/eye.gif`}/>
            <img src={`/../gifs/eye.gif`}/>
            <img src={`/../gifs/eye.gif`}/>
            <img src={`/../gifs/eye.gif`}/>
            <img src={`/../gifs/eye.gif`}/>
         </div>

         <div style={{marginBottom: '60px', textAlign: 'center'}}>
            <b>THE STEECH</b> is a recording artist based in NY.
         </div>

   		<div 
            style={{
               margin: 'auto',
               display: 'flex',
               flexDirection: 'row',
               justifyContent: 'center',
               marginBottom: '60px',
               width: '80%'
            }}
         >
            <img src={`/../gifs/flowers.gif`}/>
         </div>
   	</div>
   </Layout>
)

export default AboutPage
